import React, { useEffect, useState } from "react";

import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import "./index.scoped.css";
export default function Card32B() {
  const GAME_ID = "CASINO_METER";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.casinoMeter}
      GAME_ID={GAME_ID}
      shortName={"cmeter"}
      gameName={"Casino Meter"}
    >
      <div className="casino-detail">
        <div className="casino-table">
          <div className="casino-table-full-box">
            <div className="cmeter-video-cards-box">
              <div className="cmeter-low">
                <div>
                  <span className="text-fancy">Low</span>
                  <span className="ms-2 text-success">
                    <b> </b>
                  </span>
                </div>
                <div className="cmeter-low-cards">
                  {casinoData?.data?.response?.cards[0]?.map((card) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      onClick={() => setBet(`Low`, casinoData, dispatch)}
                      className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                        />
                      </div>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                      >
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          "Low",
                          casinoData?.data?.response?.marketId
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="cmeter-high">
                <div>
                  <span className="text-fancy">High</span>
                  <span className="ms-2 text-success">
                    <b> </b>
                  </span>
                </div>
                <div className="cmeter-high-cards">
                  {casinoData?.data?.response?.cards[1]?.map((card) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                        />
                      </div>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-center casino-odds-dwn ng-tns-c1798067373-0"
                      >
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          "High",
                          casinoData?.data?.response?.marketId
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="casino-table-box mt-3">
            <div
              className={`casino-table-left-box ${
                casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
              } `}
            >
              <div className=" text-center">
                <b className="text-info">Low</b>
              </div>
              <div className="cmeter-card-box mt-2">
                {CARD_ARRAY?.map((item) => (
                  <div className="card-odd-box">
                    <div
                      onClick={() => setBet(`Low`, casinoData, dispatch)}
                      className=""
                    >
                      <img
                        src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="casino-nation-book text-center mt-2" />
            </div>
            <div
              className={`casino-table-left-box ${
                casinoData?.data?.response.autoTime < 5 ? "suspended-box" : ""
              } `}
            >
              <div className=" text-center">
                <b className="text-info">High</b>
              </div>
              <div className="cmeter-card-box mt-2">
                {CARD_ARRAY?.map((item) => (
                  <div className="card-odd-box">
                    <div
                      onClick={() => setBet(`High`, casinoData, dispatch)}
                      className=""
                    >
                      <img
                        src={`https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/${item}.png`}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="casino-nation-book text-center mt-2" />
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
