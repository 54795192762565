import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import CasinoPage from "./components/CasinoPage";
export default function DragonTigerLion() {
  const GAME_ID = "DRAGON_TIGER_LION_20";
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("DRAGON");
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );

  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.twentyTwentyDTL}
      GAME_ID={GAME_ID}
      shortName={"dtl20"}
      gameName={"20-20 D T L"}
    >
      <div className="casino-table">
        <div className="casino-table-box d-none d-md-flex">
          <div className="casino-table-left-box">
            <div className="casino-table-header">
              <div className="casino-nation-detail" />
              <div className="casino-odds-box back">Dragon</div>
              <div className="casino-odds-box back">Tiger</div>
              <div className="casino-odds-box back">Lion</div>
            </div>
            <div className="casino-table-body">
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">Winner</div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">2.94</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">2.94</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">2.94</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    Black
                    {/* <span className="card-icon ms-1">
                      <span className="card-black ">{"}"}</span>
                    </span>
                    <span className="card-icon ms-1">
                      <span className="card-black ">]</span>
                    </span> */}
                    <span className="casino-odds" style={{ padding: "15px" }}>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                          data-v-53a3d43f=""
                          style={{ width: "18px", height: "18px" }}
                        />
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                          data-v-53a3d43f=""
                          style={{ width: "18px", height: "18px" }}
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.97</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.97</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.97</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    Red
                    {/* <span className="card-icon ms-1">
                      <span className="card-red ">{"{"}</span>
                    </span>
                    <span className="card-icon ms-1">
                      <span className="card-red ">[</span>
                    </span> */}
                    <span className="casino-odds" style={{ padding: "15px" }}>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                          data-v-53a3d43f=""
                          style={{ width: "18px", height: "18px" }}
                        />
                      </span>{" "}
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                          data-v-53a3d43f=""
                          style={{ width: "18px", height: "18px" }}
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.97</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.97</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.97</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">Odd</div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.83</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.83</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">1.83</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">Even</div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">2.12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">2.12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">2.12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/A.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/2.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/3.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/4.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
            </div>
          </div>
          <div className="casino-table-right-box">
            <div className="casino-table-header">
              <div className="casino-nation-detail" />
              <div className="casino-odds-box back">Dragon</div>
              <div className="casino-odds-box back">Tiger</div>
              <div className="casino-odds-box back">Lion</div>
            </div>
            <div className="casino-table-body">
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/5.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/6.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/7.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/8.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/9.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/10.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/J.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/Q.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
              <div className="casino-table-row">
                <div className="casino-nation-detail">
                  <div className="casino-nation-name">
                    <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/K.png" />
                  </div>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
                <div className="casino-odds-box back">
                  <span className="casino-odds">12</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="d-xl-none nav nav-pills" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id="uncontrolled-tab-example-tab-0"
              role="tab"
              data-rr-ui-event-key={0}
              aria-controls="uncontrolled-tab-example-tabpane-0"
              aria-selected="true"
              className="nav-link active"
            >
              Dragon
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id="uncontrolled-tab-example-tab-1"
              role="tab"
              data-rr-ui-event-key={1}
              aria-controls="uncontrolled-tab-example-tabpane-1"
              aria-selected="false"
              tabIndex={-1}
              className="nav-link"
            >
              Tiger
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id="uncontrolled-tab-example-tab-2"
              role="tab"
              data-rr-ui-event-key={2}
              aria-controls="uncontrolled-tab-example-tabpane-2"
              aria-selected="false"
              tabIndex={-1}
              className="nav-link"
            >
              Lion
            </button>
          </li>
        </ul>
        <div className="tab-content">
          <div
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-0"
            aria-labelledby="uncontrolled-tab-example-tab-0"
            className="fade d-xl-none tab-pane active show"
          >
            <div className="casino-table-box">
              <div className="casino-table-left-box">
                <div className="casino-table-body">
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Winner</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">2.94</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        Black
                        <span className="card-icon ms-1">
                          <span className="card-black ">{"}"}</span>
                        </span>
                        <span className="card-icon ms-1">
                          <span className="card-black ">]</span>
                        </span>
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.97</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        Red
                        <span className="card-icon ms-1">
                          <span className="card-red ">{"{"}</span>
                        </span>
                        <span className="card-icon ms-1">
                          <span className="card-red ">[</span>
                        </span>
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.97</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Odd</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.83</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Even</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">2.12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/A.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/2.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/3.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/4.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="casino-table-right-box">
                <div className="casino-table-body">
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/5.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/6.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/7.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/8.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/9.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/10.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/J.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/Q.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/K.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-1"
            aria-labelledby="uncontrolled-tab-example-tab-1"
            className="fade d-xl-none tab-pane"
          >
            <div className="casino-table-box">
              <div className="casino-table-left-box">
                <div className="casino-table-body">
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Winner</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">2.94</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        Black
                        <span className="card-icon ms-1">
                          <span className="card-black ">{"}"}</span>
                        </span>
                        <span className="card-icon ms-1">
                          <span className="card-black ">]</span>
                        </span>
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.97</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        Red
                        <span className="card-icon ms-1">
                          <span className="card-red ">{"{"}</span>
                        </span>
                        <span className="card-icon ms-1">
                          <span className="card-red ">[</span>
                        </span>
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.97</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Odd</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.83</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Even</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">2.12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/A.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/2.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/3.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/4.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="casino-table-right-box">
                <div className="casino-table-body">
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/5.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/6.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/7.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/8.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/9.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/10.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/J.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/Q.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/K.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-2"
            aria-labelledby="uncontrolled-tab-example-tab-2"
            className="fade d-xl-none tab-pane"
          >
            <div className="casino-table-box">
              <div className="casino-table-left-box">
                <div className="casino-table-body">
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Winner</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">2.94</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        Black
                        <span className="card-icon ms-1">
                          <span className="card-black ">{"}"}</span>
                        </span>
                        <span className="card-icon ms-1">
                          <span className="card-black ">]</span>
                        </span>
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.97</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        Red
                        <span className="card-icon ms-1">
                          <span className="card-red ">{"{"}</span>
                        </span>
                        <span className="card-icon ms-1">
                          <span className="card-red ">[</span>
                        </span>
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.97</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Odd</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">1.83</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">Even</div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">2.12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/A.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/2.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/3.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/4.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="casino-table-right-box">
                <div className="casino-table-body">
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/5.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/6.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/7.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/8.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/9.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/10.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/J.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/Q.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                  <div className="casino-table-row">
                    <div className="casino-nation-detail">
                      <div className="casino-nation-name">
                        <img src="https://versionobj.ecoassetsservice.com/v21/static/front/img/cards/K.png" />
                      </div>
                    </div>
                    <div className="casino-odds-box back">
                      <span className="casino-odds">12</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
