import React from "react";
import { unSetteledBetsAPI } from "../../service/AuthService";
import moment from "moment";

export default function UnSetteledBet() {
  const [betType, setBetType] = React.useState("MATCHED");
  const [data, setData] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(0);
  const [filter, setFilter] = React.useState({
    sport: null,
    mode: "all",

    search: "",
    totalBets: 0,
    totalAmount: 0,
    page: 0,
    totalPages: 1,
    size: 10,
  });
  const getUnsetteledBets = async () => {
    const { response, code } = await unSetteledBetsAPI({
      betType,
      pageSize,
      pageNumber,
    });
    if (code == 200) {
      if (filter.sport == "sport") {
        response.content = response.content.filter(
          (item) => item.event_type != "Casino"
        );
      } else if (filter.sport == "Casino") {
        response.content = response.content.filter(
          (item) => item.event_type === filter.sport
        );
      } else {
        response.content = response.content;
      }
      if (filter.mode === "back") {
        response.content = response.content.filter(
          (item) => item.back_rate || item.back_run
        );
      } else if (filter.mode === "lay") {
        response.content = response.content.filter(
          (item) => item.lay_rate || item.lay_run
        );
      } else {
        response.content = response.content;
      }
      setData(response);
      setFilter((prev) => ({
        ...prev,
        page: response.pageable.pageNumber,
        totalBets: response.totalElements,
        totalAmount: response.content.reduce((acc, item) => {
          if (item?.amount) {
            return parseInt(acc) + parseInt(item.amount);
          } else {
            return 0;
          }
        }, 0),
        totalPages: response.totalPages,
        size: response.size,
      }));
    }
  };
  React.useEffect(() => {
    getUnsetteledBets();
  }, [filter.mode, filter.sport, filter.page, filter.size]);
  const isMobile = window.innerWidth < 786;
  return (
    <div className={`col-12 report-main-content m-t-5`}>
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Current Bets</h4>
        </div>
        <div className="card-body">
          <div className="report-form">
            <form className="row row5">
              <div className="col-lg-2 col-md-3">
                <div className="mb-2 input-group position-relative">
                  <select
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        sport: e.target.value,
                      }));
                    }}
                    className="form-select"
                    name="gtype"
                  >
                    <option value="" disabled="">
                      Select Report Type
                    </option>
                    <option value={"sport"}>Sports</option>
                    <option value={"Casino"}>Casino</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 d-grid">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
            <div className="row row5 mt-2 justify-content-between align-items-center">
              <div className="col-lg-2 col-5">
                <div className="mb-2 input-group position-relative">
                  <span className="me-2">Show</span>
                  <select
                    value={filter.size}
                    onChange={(e) => {
                      setPageSize(e.target.value);
                      getUnsetteledBets();
                    }}
                    className="form-select"
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </select>
                  <span className="ms-2">Entries</span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-7 text-center">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="all"
                    name="filter"
                    defaultValue="all"
                    onClick={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        mode: "all",
                      }))
                    }
                    defaultChecked={filter?.mode === "all"}
                  />
                  All
                  <label className="form-check-label" htmlFor="all" />
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="back"
                    name="filter"
                    onChange={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        mode: "back",
                      }))
                    }
                    defaultValue={filter?.mode == "back"}
                  />
                  Back
                  <label className="form-check-label" htmlFor="back" />
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="lay"
                    name="filter"
                    onChange={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        mode: "lay",
                      }))
                    }
                    defaultValue={filter?.mode == "lay"}
                  />
                  Lay
                  <label className="form-check-label" htmlFor="lay" />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-left col-7">
                <div>
                  Total Bets: <span className="me-2">{filter.totalBets}</span>{" "}
                  Total Amount:{" "}
                  <span className="me-2">{filter.totalAmount}</span>
                </div>
              </div>
              <div className="col-lg-2 col-5">
                <div className="mb-2 input-group position-relative">
                  <span className="me-2">Search:</span>
                  <input
                    type="search"
                    onChange={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        search: e.target.value,
                      }))
                    }
                    className="form-control"
                    placeholder="0 records..."
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 table-responsive">
              <table
                role="table"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr role="row">
                    <th
                      colSpan={1}
                      role="columnheader"
                      className="report-sport"
                    >
                      Sports
                    </th>
                    <th colSpan={1} role="columnheader">
                      Event Name
                    </th>
                    <th colSpan={1} role="columnheader">
                      Market Name
                    </th>
                    <th colSpan={1} role="columnheader">
                      Nation
                    </th>
                    <th
                      colSpan={1}
                      role="columnheader"
                      className="report-amount text-end"
                    >
                      User Rate
                    </th>
                    <th
                      colSpan={1}
                      role="columnheader"
                      className="report-amount text-end"
                    >
                      Amount
                    </th>
                    <th
                      colSpan={1}
                      role="columnheader"
                      className="report-date"
                    >
                      Place Date
                    </th>
                    <th
                      colSpan={1}
                      role="columnheader"
                      className="report-action"
                    >
                      <div className="text-end">
                        <div className="form-check form-check-inline">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            title="Toggle All Current Page Rows Selected"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  {data?.content?.map((item, index) => (
                    <tr role="row" key={index}>
                      <td role="cell" className="report-sport">
                        {item.event_type}
                      </td>
                      <td role="cell">{item.event_name}</td>
                      <td role="cell">{item.marketName}</td>
                      <td role="cell">{item.runner_name}</td>
                      <td role="cell" className="report-amount text-end">
                        {item?.event_type == "Casino" ?
                        item?.back_run||item?.lay_run
                        : item.back_rate ? item?.back_rate : item?.lay_rate}
                      </td>
                      <td role="cell" className="report-amount text-end">
                        {item.amount}
                      </td>
                      <td role="cell" className="report-date">
                        {moment(item.created_date).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </td>
                      <td role="cell" className="report-action">
                        <div className="text-end">
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              title="Toggle All Current Page Rows Selected"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={8}>
                      <div className="d-flex justify-content-between">
                        <div>
                          Showing {filter.page * filter.size + 1} to{" "}
                          {filter.page * filter.size + filter.size} of{" "}
                          {filter.totalBets} entries
                        </div>
                        <div>
                          <nav>
                            <ul className="pagination">
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  onClick={() => {
                                    if (filter.page > 0) {
                                      setFilter((prev) => ({
                                        ...prev,
                                        page: prev.page - 1,
                                      }));
                                    }
                                  }}
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">«</span>
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  {filter.page + 1}
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  {filter.page + 2}
                                </a>
                              </li>

                              <li className="page-item">
                                <a
                                  className="page-link"
                                  onClick={() => {
                                    if (filter.page + 1 < filter.totalPages) {
                                      setFilter((prev) => ({
                                        ...prev,
                                        page: prev.page + 1,
                                      }));
                                    }
                                  }}
                                  aria-label="Next"
                                >
                                  <span aria-hidden="true">»</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
