import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import CasinoVideo from "./components/CasinoVideo";
import PlacedBet from "./components/PlacedBet";
import Timer from "./components/Timer";
import CasinoPage from "./components/CasinoPage";
export default function Race20() {
  const GAME_ID = "RACE20";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const CARD_MAPPING = {
    S: 1,
    H: 2,
    D: 3,
    C: 4,
  };
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [selectedTab, setSelectedTab] = React.useState("game");
  const isMobile = window.innerWidth < 768;
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.race2020}
      GAME_ID={GAME_ID}
      gameName={"RACE 20"}
      shortName={"race20"}
    >
      <div className="casino-table ng-tns-c3502172659-2">
        <div className="casino-table-box casino-table-box_bg ng-tns-c3502172659-2">
          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
            <div className="casino-nation-name ng-tns-c3502172659-2">
              <img
                className="ng-tns-c3502172659-2 ng-star-inserted"
                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KHH.webp"
              />

              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                <a
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                  href="#collapserange2r20201"
                  aria-controls="collapserange21"
                >
                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                </a>
                <div
                  className="range-collpase collapse ng-tns-c3502172659-2"
                  id="collapserange2r20201"
                >
                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Min:</b>
                    <span className="ng-tns-c3502172659-2">100</span>
                  </span>
                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Max:</b>
                    <span className="ng-tns-c3502172659-2">100000</span>
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData?.data
                ?.response?.autoTime < 5 && "suspended-box"}`}
            >
              <div
                className="casino-odds-box back ng-tns-c3502172659-2"
                onClick={() =>
                  setBet("K of spade", casinoData, dispatch, "back")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of spade", casinoData, true)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of spade", casinoData, true, true)}{" "}
                </div>
              </div>
              <div
                className="casino-odds-box lay ng-tns-c3502172659-2"
                onClick={() =>
                  setBet("K of spade", casinoData, dispatch, "lay")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of spade", casinoData, false)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of spade", casinoData, false, true)}{" "}
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
              {getCasinoPlExposureByRunner(casinoPlData, "K of spade")}{" "}
            </div>
          </div>
          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
            <div className="casino-nation-name ng-tns-c3502172659-2">
              <img
                className="ng-tns-c3502172659-2 ng-star-inserted"
                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KDD.webp"
              />

              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                <a
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                  href="#collapserange2r20202"
                  aria-controls="collapserange22"
                >
                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                </a>
                <div
                  className="range-collpase collapse ng-tns-c3502172659-2"
                  id="collapserange2r20202"
                >
                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Min:</b>
                    <span className="ng-tns-c3502172659-2">100</span>
                  </span>
                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Max:</b>
                    <span className="ng-tns-c3502172659-2">100000</span>
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData?.data
                ?.response?.autoTime < 5 && "suspended-box"}`}
            >
              <div
                onClick={() =>
                  setBet("K of heart", casinoData, dispatch, "back")
                }
                className="casino-odds-box back ng-tns-c3502172659-2"
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of heart", casinoData, true)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of heart", casinoData, true, true)}{" "}
                </div>
              </div>
              <div
                onClick={() =>
                  setBet("K of heart", casinoData, dispatch, "lay")
                }
                className="casino-odds-box lay ng-tns-c3502172659-2"
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of heart", casinoData, false)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of heart", casinoData, false, true)}{" "}
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
              {getCasinoPlExposureByRunner(casinoPlData, "K of heart")}{" "}
            </div>
          </div>
          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
            <div className="casino-nation-name ng-tns-c3502172659-2">
              <img
                className="ng-tns-c3502172659-2 ng-star-inserted"
                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KCC.webp"
              />

              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                <a
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                  href="#collapserange2r20203"
                  aria-controls="collapserange23"
                >
                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                </a>
                <div
                  className="range-collpase collapse ng-tns-c3502172659-2"
                  id="collapserange2r20203"
                >
                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Min:</b>
                    <span className="ng-tns-c3502172659-2">100</span>
                  </span>
                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Max:</b>
                    <span className="ng-tns-c3502172659-2">100000</span>
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData?.data
                ?.response?.autoTime < 5 && "suspended-box"}`}
            >
              <div
                onClick={() =>
                  setBet("K of club", casinoData, dispatch, "back")
                }
                className="casino-odds-box back ng-tns-c3502172659-2"
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of club", casinoData, true)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of club", casinoData, true, true)}{" "}
                </div>
              </div>
              <div
                onClick={() => setBet("K of club", casinoData, dispatch, "lay")}
                className="casino-odds-box lay ng-tns-c3502172659-2"
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of club", casinoData, false)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of club", casinoData, false, true)}{" "}
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
              {getCasinoPlExposureByRunner(casinoPlData, "K of club")}
            </div>
          </div>
          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
            <div className="casino-nation-name ng-tns-c3502172659-2">
              <img
                className="ng-tns-c3502172659-2 ng-star-inserted"
                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KSS.webp"
              />

              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                <a
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                  href="#collapserange2r20204"
                  aria-controls="collapserange24"
                >
                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                </a>
                <div
                  className="range-collpase collapse ng-tns-c3502172659-2"
                  id="collapserange2r20204"
                >
                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Min:</b>
                    <span className="ng-tns-c3502172659-2">100</span>
                  </span>
                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2">Max:</b>
                    <span className="ng-tns-c3502172659-2">100000</span>
                  </span>
                </div>
              </div>
            </div>

            <div
              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData?.data
                ?.response?.autoTime < 5 && "suspended-box"}`}
            >
              <div
                onClick={() =>
                  setBet("K of diamond", casinoData, dispatch, "back")
                }
                className="casino-odds-box back ng-tns-c3502172659-2"
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of diamond", casinoData, true)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of diamond", casinoData, true, true)}{" "}
                </div>
              </div>
              <div
                onClick={() =>
                  setBet("K of diamond", casinoData, dispatch, "lay")
                }
                className="casino-odds-box lay ng-tns-c3502172659-2"
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("K of diamond", casinoData, false)}{" "}
                </span>
                <div className="casino-volume ng-tns-c3502172659-2">
                  {getOddForEntity("K of diamond", casinoData, false, true)}{" "}
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
              {getCasinoPlExposureByRunner(casinoPlData, "K of diamond")}{" "}
            </div>
          </div>
        </div>
        <div className="casino-table-box mt-2 ng-tns-c3502172659-2">
          <div className="casino-table-left-box ng-tns-c3502172659-2">
            <div className="casino-odd-box-container ng-tns-c3502172659-2">
              <div className="casino-nation-name ng-tns-c3502172659-2" />
              <div className="casino-nation-name ng-tns-c3502172659-2">No</div>
              <div className="casino-nation-name ng-tns-c3502172659-2">Yes</div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Total points{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    href="#collapserange2rac2020513"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapserange2rac2020513"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    id="collapserange2rac2020513"
                    className="range-collpase collapse ng-tns-c3502172659-2"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100</span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">100000</span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  setBet("Total point", casinoData, dispatch, "lay")
                }
                className={`ng-tns-c3502172659-2 casino-odds-box lay ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Total point", casinoData, false)}{" "}
                </span>
                <div className="casino-volume text-center ng-tns-c3502172659-2">
                  {getOddForEntity("Total point", casinoData, false, true)}
                </div>
              </div>
              <div
                onClick={() =>
                  setBet("Total point", casinoData, dispatch, "back")
                }
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Total point", casinoData, true)}{" "}
                </span>
                <div className="casino-volume text-center ng-tns-c3502172659-2">
                  {getOddForEntity("Total point", casinoData, true, true)}
                </div>
              </div>
              <div className="casino-nation-book ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Total point")}{" "}
              </div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2">
              <div className="casino-nation-name ng-tns-c3502172659-2" />
              <div className="casino-nation-name ng-tns-c3502172659-2">No</div>
              <div className="casino-nation-name ng-tns-c3502172659-2">Yes</div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Total cards{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    href="#collapserange2rac2020614"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapserange2rac2020614"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    id="collapserange2rac2020614"
                    className="range-collpase collapse ng-tns-c3502172659-2"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100</span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">100000</span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  setBet("Total card", casinoData, dispatch, "lay")
                }
                className={`ng-tns-c3502172659-2 casino-odds-box lay ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Total card", casinoData, false)}
                </span>
                <div className="casino-volume text-center ng-tns-c3502172659-2">
                  {getOddForEntity("Total card", casinoData, false, true)}{" "}
                </div>
              </div>
              <div
                onClick={() =>
                  setBet("Total card", casinoData, dispatch, "back")
                }
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Total card", casinoData, true)}
                </span>
                <div className="casino-volume text-center ng-tns-c3502172659-2">
                  {getOddForEntity("Total card", casinoData, true, true)}{" "}
                </div>
              </div>
              <div className="casino-nation-book ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Total card")}{" "}
              </div>
            </div>
          </div>
          <div className="casino-table-right-box right_box_padding ng-tns-c3502172659-2">
            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Win with 5{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                    href="#collapserange2race207"
                    aria-controls="collapserange2race207"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    className="range-collpase collapse ng-tns-c3502172659-2"
                    id="collapserange2race207"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100</span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">
                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                          25000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
                onClick={() =>
                  setBet("Win with 5", casinoData, dispatch, "back")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Win with 5", casinoData, true)}{" "}
                </span>
              </div>
              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Win with 5")}
              </div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Win with 6{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                    href="#collapserange2race208"
                    aria-controls="collapserange2race208"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    className="range-collpase collapse ng-tns-c3502172659-2"
                    id="collapserange2race208"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100</span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">
                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                          25000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
                onClick={() =>
                  setBet("Win with 6", casinoData, dispatch, "back")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Win with 6", casinoData, true)}{" "}
                </span>
              </div>
              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Win with 6")}
              </div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Win with 7{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                    href="#collapserange2race209"
                    aria-controls="collapserange2race209"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    className="range-collpase collapse ng-tns-c3502172659-2"
                    id="collapserange2race209"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100</span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">
                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                          25000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
                onClick={() =>
                  setBet("Win with 7", casinoData, dispatch, "back")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Win with 7", casinoData, true)}
                </span>
              </div>
              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Win with 7")}
              </div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Win with 15{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                    href="#collapserange2race2010"
                    aria-controls="collapserange2race2010"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    className="range-collpase collapse ng-tns-c3502172659-2"
                    id="collapserange2race2010"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100</span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">
                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                          25000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
                onClick={() =>
                  setBet("Win with 15", casinoData, dispatch, "back")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Win with 15", casinoData, true)}
                </span>
              </div>
              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Win with 15")}
              </div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Win with 16{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                    href="#collapserange2race2011"
                    aria-controls="collapserange2race2011"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    className="range-collpase collapse ng-tns-c3502172659-2"
                    id="collapserange2race2011"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100</span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">
                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                          25000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
                onClick={() =>
                  setBet("Win with 16", casinoData, dispatch, "back")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Win with 16", casinoData, true)}
                </span>
              </div>
              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Win with 16")}
              </div>
            </div>
            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
              <div className="casino-nation-name ng-tns-c3502172659-2">
                Win with 17{" "}
                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                  <a
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                    href="#collapserange2race2012"
                    aria-controls="collapserange2race2012"
                  >
                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                  </a>
                  <div
                    className="range-collpase collapse ng-tns-c3502172659-2"
                    id="collapserange2race2012"
                  >
                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Min:</b>
                      <span className="ng-tns-c3502172659-2">100 </span>
                    </span>
                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                      <b className="ng-tns-c3502172659-2">Max:</b>
                      <span className="ng-tns-c3502172659-2">
                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                          25000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                  ?.data?.response?.autoTime < 5 && " suspended-box"}`}
                onClick={() =>
                  setBet("Win with 17", casinoData, dispatch, "back")
                }
              >
                <span className="casino-odds ng-tns-c3502172659-2">
                  {getOddForEntity("Win with 17", casinoData, true)}
                </span>
              </div>
              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                {getCasinoPlExposureByRunner(casinoPlData, "Win with 17")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
